<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Produto CASSEMS</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
    >
      <v-row
        justify="center"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="targetProduct.title"
            :rules="$rules.required"
            required
            outlined
            dense
            label="Nome *"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>
import TargetProductService from '../../../services/target-product.service';

export default {
  name: 'Form',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      targetProduct: {},
      isLoading: false,
    };
  },

  created() {
    if (this.populateWith.id) {
      this.targetProduct = this.populateWith;
    }
  },

  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async createTargetProduct(targetProduct) {
      try {
        this.isLoading = true;
        const data = await TargetProductService.create(targetProduct);
        this.$toast.success('Produto CASSEMS salvo com sucesso');
        this.isLoading = false;
        return data;
      } catch (e) {
        this.isLoading = false;
        this.$handleHttpError(e);
      }
    },

    async updateTargetProduct(targetProduct) {
      try {
        this.isLoading = true;
        const data = await TargetProductService.update(targetProduct);
        this.$toast.success('Produto CASSEMS salvo com sucesso');
        this.isLoading = false;
        return data;
      } catch (e) {
        this.isLoading = false;
        this.$handleHttpError(e);
      }
    },

    async submit() {
      if (!this.$refs.form.validate()) return;

      const targetProduct = {
        ...this.targetProduct,
      };

      if (this.targetProduct.id) {
        const update = await this.updateTargetProduct(targetProduct);
        this.$emit('target-product-edited', update);
      } else {
        const createdProduct = await this.createTargetProduct(targetProduct);
        this.$emit('target-product-added', createdProduct);
        this.targetProduct.title = '';
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>

<style scoped>

</style>
