<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Produtos CASSEMS</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <target-product-form
        v-if="dialog"
        :populate-with="targetProductToEdit"
        @close-dialog="closeDialog"
        @target-product-edited="editItem"
        @target-product-added="addTargetProduct"
      />
    </v-dialog>
  </div>
</template>

<script>
import Form from '@/views/main/target-product/Form';
import TargetProductService from '../../../services/target-product.service';

export default {
  name: 'List',
  components: {
    'target-product-form': Form,
  },
  data() {
    return {
      headers: [
        {
          text: 'Nome',
          sortable: false,
          value: 'title',
        },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      dialog: false,
      targetProductToEdit: {},
    };
  },

  async created() {
    try {
      this.items = await TargetProductService.findAll();
    } catch (e) {
      this.$handleHttpError(e);
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.targetProductToEdit = {};
    },
    editItem(item) {
      this.targetProductToEdit = item;
      this.dialog = true;
    },
    async deleteItem(item) {
      try {
        await TargetProductService.deleteOne(item.id);
        const index = this.items.findIndex((product) => product.id === item.id);
        this.items.splice(index, 1);
        this.$toast.success('Produto excluído com sucesso');
      } catch (e) {
        this.$handleHttpError(e);
      }
    },
    addTargetProduct(targetProduct) {
      this.items.push(targetProduct);
    },
    editTargetProduct(targetProduct) {
      const index = this.items.findIndex((product) => product.id === targetProduct.id);
      this.items[index] = targetProduct;
    },
  },
};
</script>

<style scoped>

</style>
